import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getMyProfile = createAsyncThunk(
  'MyProfile/getMyProfile',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/myprofile', params,{ withCredentials:true, headers })
      localStorage.setItem('userData', JSON.stringify(response?.data?.data))
      return {
        myProfile: response?.data?.data,
      }
      
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setProfileLoader(false))
    }
  }
)


  export const updatePersonalDetails = createAsyncThunk(
    'MyProfile/updatePersonalDetails',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/updatepersonaldetails', params,{ withCredentials:true, headers })
    dispatch(setProfileLoader(true));
    dispatch(getMyProfile())
        return true
      } catch (e) {
        toast.error(e?.response?.data?.message)
        dispatch(setProfileLoader(false))
      }
    }
  )

  export const updateBankDetails = createAsyncThunk(
    'MyProfile/updateBankDetails',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/updatebankdetails', params,{ withCredentials:true, headers })
    dispatch(setProfileLoader(true));
    dispatch(getMyProfile())
        return true
      } catch (e) {
        toast.error(e?.response?.data?.message)
        dispatch(setProfileLoader(false))
      }
    }
  )

  export const updateKYCDeatils = createAsyncThunk(
    'MyProfile/updateKYCDeatils',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/updatekycdetails', params,{ withCredentials:true, headers })
        dispatch(setProfileLoader(true))
        dispatch(getMyProfile())
        return {
          kycStatus:response?.data?.status,
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
        dispatch(setProfileLoader(false))
        dispatch(setLoader(false))
      }
    }
  )

  export const updatePassword = createAsyncThunk(
    'MyProfile/updatePassword',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/updateprofilepassword', params,{ withCredentials:true, headers })
    dispatch(setProfileLoader(true));
    dispatch(getMyProfile())
        return true
      } catch (e) {
        toast.error(e?.response?.data?.message)
        dispatch(setProfileLoader(false))
      }
    }
  )



  export const sendAdharOTP = createAsyncThunk(
    'MyProfile/updatePassword',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/sendAdharOTP', params,{ withCredentials:true, headers })
        toast.success(response?.data?.message)
        return {
          message:response?.data?.message,
          requestId:response?.data?.data?.response?.requestId,
          loader: false,
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
        dispatch(setProfileLoader(false))
      }
    }
  )

  export const validateAdharOTP = createAsyncThunk(
    'MyProfile/validateAdharOTP',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/validateAdharOTP', params,{ withCredentials:true, headers })
        toast.success('Aadhar Verification is Done',{duration: 8000})
        return {
          AdharOTPMsg:response?.data?.message,
          AdharStatus:response?.data?.data?.status?.statusCode,
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
        dispatch(setProfileLoader(false))
      }
    }
  )

  export const verifyPan = createAsyncThunk(
    'MyProfile/verifyPan',
    async (params, { dispatch }) => {
      console.log('params', params)
      const headers = {}
      try {
        const response = await axios.post(baseUrl +'/userapi/verifyPan', params,{ withCredentials:true, headers })
        toast.success('Pan Verification is Done',{duration: 8000})
        return {
          PanStatus:response?.data?.status,
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
        dispatch(setProfileLoader(false))
      }
    }
  )

export const MyProfile = createSlice({
  name: 'MyProfile',
  initialState: {
    myProfile: {},
    headerDeatils:[],
    profileLoader: true,
    loader: true,
    message: '',
    AdharOTPMsg: '',
    kycStatus:"",
    PanStatus:"",
    AdharStatus:"",
    requestId:"",
    selected: null,
    addharLoader:false,
    panLoader:true,
  },
  reducers: {
    selectProfile: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPanStatus: (state, action) => {
      state.PanStatus = action.payload
    },
    setAdharStatus: (state, action) => {
      state.AdharStatus = action.payload
    },
    setAddharLoader: (state, action) => {
      state.addharLoader = action.payload
    },
    setPanLoader: (state, action) => {
      state.panLoader = action.payload
    },
    setRequestId: (state, action) => {
      if (action.payload === null) {
        state.requestId = null
      } else {
        state.requestId = action.payload
      }
    },
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setProfileLoader: (state, action) => {
      state.profileLoader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMyProfile.fulfilled, (state, action) => {
      state.myProfile = action?.payload?.myProfile
      state.selected = action?.payload?.selected
      state.profileLoader = false
    })

    builder.addCase(sendAdharOTP.fulfilled, (state, action) => {
      state.message = action?.payload?.message
      state.requestId= action?.payload?.requestId,
      state.addharLoader = false
    })

    builder.addCase(validateAdharOTP.fulfilled, (state, action) => {
      state.AdharOTPMsg = action?.payload?.AdharOTPMsg
      state.AdharStatus = action?.payload?.AdharStatus
      state.addharLoader = false
    })

    builder.addCase(verifyPan.fulfilled, (state, action) => {
      state.PanStatus = action?.payload?.PanStatus
      state.panLoader = false
    })

    builder.addCase(updateKYCDeatils.fulfilled, (state, action) => {
      state.kycStatus = action?.payload?.kycStatus
      state.loader = false
    })
   
  }
})

export const { selectProfile, setLoader,setProfileLoader,setPanLoader,setPanStatus,setAdharStatus,setAddharLoader,setMessage,setRequestId, setPageNo, setPageSize, setSearch } =
MyProfile.actions

export default MyProfile.reducer