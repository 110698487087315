import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getOpenPosition = createAsyncThunk(
  'OpenPosition/getOpenPosition',
  async (type, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/my-openposition', type,{ withCredentials:true, headers,params:{
        type:type.type,
        page:type.page,
        brokerId:type.brokerId,
        status:type.trickStatus,
      } })
      return {
        list: response?.data?.data?.list,
        total: response?.data?.data?.total,
        // noofPages: response?.data?.data?.noofPages,
        // pageNumber: response?.data?.data?.pageNumber,
        // pageSize: response?.data?.data?.pageSize,
        //  total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const getRaTradeList = createAsyncThunk(
  'OpenPosition/getRaStrategyTradeList',
  async (type, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/getRaTradeList', type,{ withCredentials:true, headers,params:{
        type:type.type,
        page:type.page,
        brokerId:type.brokerId,
        status:type.trickStatus,
      } })
      return {
        list: response?.data?.data?.list,
        total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const getPortfolioTrcikList = createAsyncThunk(
  'OpenPosition/getPortfolioTrcikList',
  async (type, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/getPortfolioTrcikList', type,{ withCredentials:true })
      return {
        list: response?.data?.data,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

// get user backtest
export const getUserBacktest = createAsyncThunk(
  'OpenPosition/getUserBacktest',
  async (type, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/getUserBacktest', type,{ withCredentials:true, headers })
      return {
        details: response?.data?.data,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)


export const modifySLTrick = createAsyncThunk(
  'TrickDetail/modifySLTrick',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +`/userapi/modifySLTrick`, params,{ withCredentials:true, headers })
      return response
    } catch (e) {
      toast.error(e?.response?.data?.message)
    return e.response
    }
  }
)

export const setAndStartBacktest = createAsyncThunk(
  'TrickDetail/setAndStartBacktest',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +`/userapi/setAndStartBacktest`, params,{ withCredentials:true, headers })
      return response
    } catch (e) {
      toast.error(e?.response?.data?.message)
      return e.response
    }
  }
)

export const setEnableDisableTrick = createAsyncThunk(
  'TrickDetail/setEnableDisableTrick',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +`/userapi/enableDisableTrickBase`, params,{ withCredentials:true, headers })
      return response
    } catch (e) {
      toast.error(e?.response?.data?.message)
      return e.response
    }
  }
)


export const OpenPosition = createSlice({
  name: 'OpenPosition',
  initialState: {
    list: [],
    ratradelist: [],
    backtestlist: [],
    portfolioTrcikList: [],
    loader: true,
    error:'',
    TrackParam:{},
    UserBacktest:{},
      pageNumber: 1,
      pageSize: 12,
      Search: '',
    total: 0,
    ratradelisttotal: 0,
    selected: null
  },
  reducers: {
    selectLevel: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload.pageNumber
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload.pageSize
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOpenPosition.fulfilled, (state, action) => {
      state.list = action?.payload?.list
      state.total = action?.payload?.total
    //   state.pageNumber = action?.payload?.pageNumber
    //   state.pageSize = action?.payload?.pageSize
    //   state.total = action?.payload?.total
      state.loader = false
    })
    builder.addCase(getRaTradeList.fulfilled, (state, action) => {
      state.ratradelist = action?.payload?.list
      state.ratradelisttotal = action?.payload?.total
      state.loader = false
    })
    builder.addCase(modifySLTrick.fulfilled, (state, action) => {
      // state.ratradelist = action?.payload?.list
      // state.ratradelisttotal = action?.payload?.total
      state.loader = false
    })
    builder.addCase(getUserBacktest.fulfilled, (state, action) => {      
      state.UserBacktest = action?.payload?.details?.details ?? {}
      state.loader = false
    })
    builder.addCase(getPortfolioTrcikList.fulfilled, (state, action) => {      
      state.portfolioTrcikList = action?.payload?.list ?? []
      state.loader = false
    })
   
  }
})

export const { selectLevel, setLoader, setPageNo, setPageSize, setSearch } =
OpenPosition.actions

export default OpenPosition.reducer