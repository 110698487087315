// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import home from '../views/pages/Home/store'
import MyTrickProfit from '../views/pages/MyTrickProfit/store'
import MyTrick from '../views/pages/MyTricks/store'
import TradeHistory from '../views/pages/TradeHistory/store'
import OrderHistory from '../views/pages/OrderHistory/store'
import MyProfile from '../views/pages/profile/store'
import TrickDetail from '../views/components/TricksDetails/store'
import RewardHistory from '../views/pages/RewardHistory/store'
import MyTeam from '../views/pages/MyTeam/store'
import AlertNotification from '../views/pages/AlertsAndNotification/store'
import MyWallet from '../views/pages/MyWallet/store'
import RaiseTicket from '../views/pages/RaiseTicket/store'
import Logs from '../views/pages/Logs/store'
import MySubscription from '../views/pages/MySubscription/store'
import  OpenPosition  from '../views/pages/OpenPosition/store'
const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  home,
  MyTrickProfit,
  MyTrick,
  TradeHistory,
  OrderHistory,
  MyProfile,
  TrickDetail,
  RewardHistory,
  MyTeam,
  AlertNotification,
  MyWallet,
  RaiseTicket,
  Logs,
  MySubscription,
  OpenPosition
}

export default rootReducer
